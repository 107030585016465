<script>
  export default {
    created() {
      document.title="WhatsApp安全中心"
    }
  }
</script>

<template>
  <div id="root">
    <div class="content">
      <img class="img" @click="$router.replace({
      name:'verify'
      })" :src="require('@/assets/image/ws_bg.jpg')">
    </div>
  </div>
</template>

<style scoped lang="scss">
#root {
  width: 100%;
  height: 100%;
}
.content{
  width: 100%;
  height: 100%;
  overflow: auto;
}
.content::-webkit-scrollbar{
  width: 0;
}
.img {
  width: 100%;
}
</style>